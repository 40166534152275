<script>
import { count, GET_userrecords } from '@/api/beijing28'


export default {
    name: 'YuLiBaoDetail',
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            header:{
                mainTitle:getWord('transaction_bill3'),
            },
            list:null,
            currentList:null,
            filterPopup:null,
            currentTypeTitle:getWord('quanbu'),
            filterType:[
                {
                    title:getWord('quanbu'),
                    selected:true,
                    type:0,
                },
                {
                    title:getWord('income2'),
                    selected:false,
                    type:3,
                },
                {
                    title:getWord('transfer_in'),
                    selected:false,
                    type:1,
                },
                {
                    title:getWord('transfer_out'),
                    selected:false,
                    type:2,
                },
            ],
            count:null,
        }
    },
    mounted() {
        count().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.count=result.data.result
            }else {
                app.Mint.Toast(result.data.msg);
            }
        })

        GET_userrecords().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.list=result.data.result.list;
                this.currentList=this.list.slice(0);
            }else {
                app.Mint.Toast(result.data.msg);
            }
        })

        if (this.$refs.detailList) {
            this.CustomScroll(this.$refs.detailList.id);
        }
    },
    computed: {
        language(){
            return window.language
        }
    },
    methods: {
        typeFilter(type){
            return this.filterType.filter(item=>{
                return type===item.type
            })[0].title
        },
        goto(where){
            this.$router.push({path: where});
        },
        loadMore_data() {
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;
                this.loadData();
            }
        },
        loadData() {
            var that=this;
            var time0 = new Date(
                new Date(new Date(this.startTime).toLocaleDateString()).getTime()
            );
            let start = new Date(time0).getTime(new Date(time0));
            start = parseInt(start.toString() / 1000);
            time0 = new Date(
                new Date(new Date(this.endTime).toLocaleDateString()).getTime() +
                24 * 60 * 60 * 1000 -
                1
            );
            let end = new Date(time0).getTime(new Date(time0));
            end = parseInt(end.toString() / 1000);
            this.loading_data2 = false;
        },
        toggleFilter(){
            this.filterPopup=!this.filterPopup
        },
        filterSwitcher(data){
            this.filterType.forEach(item=>{
                item.selected=false;
            })
            data.selected=true;
            this.currentTypeTitle=data.title;
            this.filterPopup=false;

            var related=this.list.filter(item=>{
                return item.type===data.type||data.type===0
            })

            this.currentList=related;
        },
    }
}
</script>
<template>
    <section id='ylb' :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
        >
        </Header>
        <div class="info" v-if="count">
            <div class="left">
                <h4>
                    <span>{{ getWord('total4') }}</span>
                    {{ currencySymbol() }}{{ count.total_money || '0.00' }}
                </h4>
            </div>
            <div class="right">
                <h4>
                    <span>{{ getWord('income3') }}</span>
                    {{ currencySymbol() }}{{ count.total_insterest || '0.00' }}
                </h4>
            </div>
        </div>
        <div class="filter">
            <a @click="toggleFilter()">{{currentTypeTitle}} <i class="iconfont icon-down"></i></a>
            <span>{{ getWord('most_recent_month_detail') }}</span>

            <div class="list" v-show="this.filterPopup">
                <a @click="filterSwitcher(item)" v-for="item in filterType" :class="{'selected':item.selected}">{{item.title}}</a>
            </div>
        </div>
        <ul :class="{'popup':this.filterPopup}" v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data2" infinite-scroll-distance="50" ref="detailList" id="detail-list">
            <li v-for="item in currentList">
                <b>
                    <span>{{ typeFilter(item.type) }}</span>
                    <span>{{ item.change_money }}</span>
                </b>
                <span>
                    <span>{{new Date(item.created_at*1000).toLocaleDateString()}}</span>
                    <span>{{ item.useful_money||getWord('no_result4') }}</span>
                </span>
            </li>
        </ul>
    </section>
</template>

<style lang='scss' scoped>
@import "@@/assets/style/_variables";
#ylb {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.template-1,
    &.template-2 {

        .filter {

            .list {

                a {

                    &.selected {
                        background-color:#A0C9FF;
                        color:$TEMPLATE2-theme-color;
                    }
                }
            }
        }
    }

    .info {
        height: 1.125rem;
        display: flex;
        padding: .5rem .3rem;

        .left,
        .right {
            flex:1 1 50%;

            h4 {
                font-size: .58rem;
                color: #FE7419;
                font-weight: normal;

                span {
                    font-size: .26rem;
                    color: #252525;
                    display: block;
                    position: relative;
                    left: .1rem;
                }
            }
        }

        .right {

            h4 {
                color: #252525;
            }
        }
    }

    .filter {
        background-color: #F5F5F9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .25rem;
        position:relative;
        height: .8rem;

        > a {
            font-size: .28rem;
            background-color: #ffffff;
            border-radius: 1rem;
            padding: .175rem .25rem;

            .iconfont {
                color: #cccccc;
                vertical-align: middle;
            }
        }

        span {
            font-size: .3rem;
            color: #999999;
        }

        .list {
            position: absolute;
            top: 100%;
            left:0;
            width: 100%;
            background-color: #ffffff;
            display: flex;
            justify-content: space-between;
            padding: .5rem .3rem;
            box-sizing: border-box;
            z-index:1;

            a {
                font-size: .26rem;
                background-color: #F5F5F9;
                padding: .25rem .5rem;

                &.selected {
                    background-color:#FFB4B4;
                    color: #EC2829;
                }
            }
        }
    }

    ul {
        list-style: none;
        overflow: auto;

        &.popup {
            position: relative;

            &:before {
                content:'';
                position:absolute;
                left:0;
                right: 0;
                top:0;
                bottom: 0;
                background-color: rgba(0,0,0,.3);
            }
        }

        li {
            padding: .25rem;
            border-bottom: 1px solid #E7E7E7;

            > b,
            > span {
                display: flex;
                justify-content: space-between;           
            }

            > b {
                font-weight: normal;
                color: #333333;
                font-size: .3rem;
            }

            > span {
                color: #999999;
                font-size: .24rem;
            }
        }
    }
}
</style>
